<template>
  <div>
    <!--Progress-->
    <v-dialog
      v-model="progress"
      max-width="290"
      persistent
      hide-overlay
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Title Bar-->
    <v-container
      class="py-2"
      fluid
    >
      <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="6"
        >
          <div class="text-h4 secondary--text">
            Help Topics
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            rounded
            color="primary"
            v-on="on"
          >
            <v-icon
              small
              left
            >
              fal fa-filter
            </v-icon>Filter
          </v-btn>
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-if="helpTopicsStore.filters.d_title"
                  cols="12"
                  sm="12"
                >
                  <v-btn
                    text
                    rounded
                    outlined
                    block
                    @click="clearFilter()"
                  >
                    <v-icon
                      small
                      left
                    >
                      fal fa-times
                    </v-icon>Clear Filters
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="filterBar.d_title"
                    label="Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-spacer />
            <v-btn
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <help-topic />
    </v-toolbar>

    <!--Pagination  -->
    <v-toolbar
      v-if="helpTopicsStore.helpTopics.data && helpTopicsStore.helpTopics.data.length > 0 && helpTopicsStore.helpTopics.last_page > 1"
      flat
      dense
      color="accent"
    >
      <!--First-->
      <v-btn
        :disabled="helpTopicsStore.helpTopics.current_page <= 1"
        icon
        @click="filter(1)"
      >
        <v-icon color="primary">
          fal fa-step-backward
        </v-icon>
      </v-btn>

      <!--Previous-->
      <v-btn
        :disabled="helpTopicsStore.helpTopics.current_page <= 1"
        icon
        @click="filter(helpTopicsStore.helpTopics.current_page - 1)"
      >
        <v-icon color="primary">
          fal fa-caret-left
        </v-icon>
      </v-btn>

      <span>{{ helpTopicsStore.helpTopics.current_page }}
        of {{ helpTopicsStore.helpTopics.last_page }}</span>

      <!--Next-->
      <v-btn
        :disabled="helpTopicsStore.helpTopics.current_page ===
          helpTopicsStore.helpTopics.last_page"
        icon
        @click="filter(helpTopicsStore.helpTopics.current_page + 1)"
      >
        <v-icon color="primary">
          fal fa-caret-right
        </v-icon>
      </v-btn>

      <!--Last-->
      <v-btn
        :disabled="helpTopicsStore.helpTopics.current_page ===
          helpTopicsStore.helpTopics.last_page"
        icon
        @click="filter(helpTopicsStore.helpTopics.last_page)"
      >
        <v-icon color="primary">
          fal fa-step-forward
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div v-if="progress !== true">
      <!--List-->
      <v-list
        v-if="helpTopicsStore.helpTopics.data && helpTopicsStore.helpTopics.data.length > 0"
        class="py-2"
        three-line
      >
        <template v-for="(item, index) in helpTopicsStore.helpTopics.data">
          <v-list-item
            :key="item.id"
            class="my-1"
            @click="openEntry(item.id)"
          >
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold light-blue--text text--darken-4"
              >
                {{ item
                  .id }}
              </v-list-item-title>
              <v-list-item-title
                class="font-weight-bold light-blue--text text--darken-4"
              >
                {{ item
                  .d_title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>
      </v-list>

      <v-list
        v-else
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
              No Help Topics
              Exist
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelpTopic from '@/components/HelpTopic';

export default {
  name: 'HelpTopics',
  components: {
    HelpTopic,
  },
  data() {
    return {
	    progress: true,
	    filterMenu: false,
	    filterBar: {
		    d_title: '',
		    page: 1,
	    },
      dialog: false,
      deleteDialog: false,
    };
  },
  computed: {
    ...mapState({
      helpTopicsStore: state => state.helpTopicsStore,
    }),
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
	    /* Grab filters */
	    const filters = this.$store.getters['helpTopicsStore/getFilters'];

	    this.filterBar.d_title = filters.d_title;

      /* Store authorized user in store */
      await this.$store.dispatch('helpTopicsStore/fetch', filters);
	    this.progress = false;
    },

	  async filter(page) {
		  this.filterMenu = false;
		  this.progress = true;

		  const data = {
			  d_title: this.filterBar.d_title,
			  page,
		  };

		  this.$store.dispatch('helpTopicsStore/storeFilters', data);
		  this.initialize();
	  },

	  async clearFilter() {
		  this.filterMenu = false;
		  this.progress = true;

		  this.filterBar.d_title = '';

		  const data = {
			  d_title: '',
			  page: 1,
		  };

		  this.$store.dispatch('helpTopicsStore/storeFilters', data);
		  this.initialize();
	  },

	  async openEntry(id) {
		  await this.$store.dispatch('helpTopicsStore/entry', id);
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
